<template>
  <div id="app">
      <Header />
      <router-view  /> 
      <Footer/>
      <AgeGateCookie />
      <CookieBannerComp  class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/> 
  </div>
</template>


<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import AgeGateCookie from "@/components/AgeGateCookie.vue"

export default {
  data() {
    return {
      
    };
  },

  components: {
   // Home,
    Header,
    Footer,
    AgeGateCookie
  },
  created(){
    this.$store.dispatch("getPlaces");
    this.$store.dispatch("getQuotes");
  }
};
</script>
<style >
.custom-control-input:checked ~.custom-control-label::before {
         background: #064D2B !important;
         border-color:#064D2B !important;
    }
</style>
