<template>
  <div class="footer" v-inview="'fade-up'">
    <div class="container-xl">
      <div class="row pt-3">
         <div class="col-12 no-ppdn-l-r">
           <div class="footer--top-section" >

            <div class="footer__logo-wrap dipslay-none-big">
              <img src="@/assets/images/design/logo.svg" alt="Gösser">
            </div>

            <div class="footer--list-item">
              <ul>
                <li>
                  <a  v-scroll-to="'#premium'" @click="setGtm('Gösser Premium')">Gösser Premium</a>
                </li>
                <li>
                  <a v-scroll-to="'#zitrone'" @click="setGtm('Gösser Natur Zitrone')">Gösser Natur Zitrone</a>
                </li>
                <li>
                  <router-link to="/beerspritz" @click="setGtm('Gösser Spritz')">Gösser Spritz</router-link>
                </li>
                <li class="dipslay-none-sm">
                  <a href=""></a>
                </li>
              </ul>
            </div>

            <div class="footer__logo-wrap dipslay-logo-none-md d-none d-xl-block">
              <img src="@/assets/images/design/logo.svg" alt="Gösser">
            </div>

            <div class="footer--list-item">
              <ul>
                <li>
                  <a v-scroll-to="'#responsibility'" @click="setGtm('Fogyaszd felelősséggel')">Fogyaszd felelősséggel</a>
                </li>
                <li>
                  <a href="https://nyeremenyjatek.heinekenhungaria.hu/" target="_blank" @click="setGtm('Promóciók')">Promóciók</a>
                </li>
                <li>
                  <a  v-scroll-to="'#contact'" @click="setGtm('Kapcsolat')">Kapcsolat</a>
                </li>
                 <li>
                    <a  class="mrgn-right-none" href="javascript:void(0)" @click="$store.state.showCookie= true;setGtm('Cookie beállítás')">
                        Cookie beállítások módosítása
                      </a>
                  </li>
              </ul>
            </div>

           </div>
         </div>
      </div>

      <div class="row">
          <div class="col-12">
            <div class="contacts--underline"></div>
          </div>
      </div>
      
      <div class="row">
        <div class="col-12">
          <div class="footer--bottom-section">

            <div class="footer-all-rights">
              <div class="footer-all-rights--txt">{{currentYear}} HEINEKEN HUNGÁRIA ZRT. MINDEN JOG FENNTARTVA</div>
            </div>

            <div class="footer-gdpr">
              <a href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato" target="_blank" style="color:white;">Adatkezelési Tájékoztató</a>
            </div>
            <div class="footer-social">               
                <a href="https://www.instagram.com/gosser_hu/" target="_blank" @click="setGtm('instagram')">
                  <img class="footer-social--icon" src="@/assets/images/contact/insta-footer.svg" alt="instagram">
                </a>
                <a href="https://www.facebook.com/GosserHU" target="_blank" @click="setGtm('facebook')">
                  <img class="footer-social--icon" src="@/assets/images/contact/facebook-footer.svg" alt="facebook">
                </a>
                <a href="https://www.youtube.com/channel/UCH-rMvmPCkJJmEO35fxe2ew/featured" target="_blank" @click="setGtm('youtube')">
                  <img class="footer-social--icon" src="@/assets/images/contact/yt-footer.svg" alt="youtube">
                </a>
            </div>

          </div>

        </div>
      </div>


    </div>

  </div>
</template>


<script>
export default {
  data(){
    return {
      currentYear: new Date().getFullYear(), 
    }
  },   
  methods:{
    setGtm(buttonText){      
      this.GTtrackGA4({
            'event' : 'event',
            'category': 'menu',
            'action': 'lablec',
            'label': buttonText,
            'menu': 'lablec',
            'clicked_text': buttonText,
            'action_type': 'menu_kattintas'
        })
    }
  }
}
</script>



