export default {
    data: function(){
        return {            
            base_url: process.env.VUE_APP_BASE_URL,                         
        }
    },       
    computed:{
        TimeStamp() {
            return Math.floor(Date.now() / 1000);
        }       
    },    
    methods: {
        scrollTop (){
            document.getElementsByTagName('html')[0].scrollTop = 0
        },        
        GTtrackGA4( dataLayerOptions ){
            // console.log("GTtrackGA4 track " , dataLayerOptions);
            try {
                window.dataLayer.push(dataLayerOptions);
            } catch (e) {
                console.log("not found dataLayer")
            }   
        },                           
    }
}
