import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
//import home from '../App.vue'
import spritz from '../views/Spritz.vue'
import spritzArticle1 from '../views/SpritzArticle1.vue'
import spritzArticle2 from '../views/SpritzArticle2.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/beerspritz',
    name: 'Spritz',
    component: spritz
  },
  {
    path: '/beerspritz/a-spritz-tortenete',
    name: 'SpritzArticle1',
    component: spritzArticle1
  },
  {
    path: '/beerspritz/dobd-fel-a-teraszod',
    name: 'SpritzArticle2',
    component: spritzArticle2
  },
  {
    path: '*',
    redirect: '/'    
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
