<template>
    <div id="ageGateCookie" class="ageGateCookie" v-if="needCookieSettings"  >
        <div class="container">
            <!-- AGE GATE -->
            <div class="ageGateCookie__ageGate row" >
                <div class="col-12">
                    <img src="@/assets/images/design/logo.svg" alt="Gösser" class="logo" />
                    <div class="ageGate__title h1" >Elmúltál már 18 éves?</div>
                    <p >Kérjük, a belépéshez add meg születési dátumod!</p>
                    <div class="ageGate__form" >
                        <input v-mask="'####'" ref="ageGateYear" v-model="ageGateInputYear" placeholder="ÉÉÉÉ" type="text" />
                        <input v-mask="'##'" ref="ageGateMonth" v-model="ageGateInputMonth" placeholder="HH" type="text" />
                        <input v-mask="'##'" ref="ageGateDay" v-model="ageGateInputDay" placeholder="NN" type="text" />
                    </div>
                    <div class="error-msg" v-if="ageGateError">{{ageGateError}}</div>
                </div>    
               
               <div class="btn-wrap mt-4 d-flex align-items-center w-100" style="justify-content: center" >
                                <button class="btn--white btn--with-arrow btn" @click="checkAgeGateAndAllCookies()"><span> Belépek</span></button>
                            </div>
                <!-- <div class="col-12 ">
                    <div class="btn-wrap-first" >
                        <div 
                            id="ageGateGoBtn"
                            class="btn--white btn--with-arrow btn"  
                            :class="{'disabled' : !ageGateFilled}"                           
                            @click="checkAgeGateAndAllCookies()"
                        >
                            <span> tovább</span>
                        </div>
                    </div>
                </div> -->

            </div>
            <!-- COOKIE POLICY           -->
            <!--<div class="row" >
                <div class="col-12 col-xl-10 offset-xl-1">
                    <div id="cookie-policy" class="ageGateCookie__cookiePolicy" >            
                        <div class="cookiePolicy__inner">
                            <div class="text">
                                <div class="cookiePolicy__title" >Cookie beállítások</div>                            
                                <p class="font--little" >A cookie-kat azért használjuk, hogy a lehető legjobb felhasználói élményt nyújtsuk Önnek. Ide tartoznak a webhely működéséhez és a kereskedelmi vállalati céljaink ellenőrzéséhez szükséges sütik, valamint azok, amelyeket csak névtelen statisztikai célokra, kényelmi beállításokhoz vagy személyre szabott tartalom megjelenítéséhez használnak. Ön maga döntheti el, mely kategóriákat kívánja engedélyezni. Felhívjuk figyelmét, hogy a beállításai alapján a webhely nem minden funkciója érhető el.</p>   
                                <button type="button" class="text-link text-link--arrow" @click="changeCookies = !changeCookies" :class="{'opened': changeCookies}" >Részletek megjelenítése</button>                             
                            </div>                        
                            <div class="cookiePolicy__settings" v-if="changeCookies" >
                                <div class="settings__inner">
                                    <div class="custom-control custom-checkbox">
                                        <input true-value="1" false-value="0" v-model="cookies.required" type="checkbox" class="custom-control-input" id="required_chbx"  disabled inputmode="decimal" />
                                        <label class="custom-control-label" for="required_chbx">Szükséges</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input true-value="1" false-value="0" v-model="cookies.statistic" type="checkbox" class="custom-control-input" id="statistics_chbx" inputmode="decimal"/>
                                        <label class="custom-control-label" for="statistics_chbx">Statisztika</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input true-value="1" false-value="0" v-model="cookies.marketing" type="checkbox" class="custom-control-input" id="marketing_chbx" inputmode="decimal"/>
                                        <label class="custom-control-label" for="marketing_chbx">Marketing</label>
                                    </div>
                                </div>                                
                                <button type="button" v-if="changeCookies" class="btn--white-border btn" @click="checkAgeGateAndSettingsCookies()"><span>Mentés</span></button>
                            </div>

                            <div class="underline-coockie-policy-container">
                                <div class="underline-coockie-policy"> </div>
                            </div>

                        
                            <div class="btn-wrap" >
                                <div class="btn--white btn--with-arrow btn" @click="checkAgeGateAndAllCookies()"><span> folytatom az ajánlott sütikkel</span></div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="row" >
                <div class="col-12">
                    <p class="ageGateCookie__nyilatkozat">
                        A belépéssel elfogadod az
                         <a :href="'./documents/Adatvedelmi_Nyilatkozat.pdf?' + TimeStamp" target="_blank" >
                        <strong style="text-decoration:underline">Adatvédelmi nyilatkozatot</strong>
                    </a>
                     és a
                      <a :href="'./documents/Cookie_Policy.pdf?' + TimeStamp" target="_blank" >
                        <strong style="text-decoration:underline">Cookie szabályzatot</strong></a></p>
                </div>                
            </div>-->
        </div>
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
    data(){
        return{
            ageGateInputYear: null,
            ageGateInputMonth :null,
            ageGateInputDay: null,
            ageGateError: null,          
            needCookieSettings: true,
            changeCookies: false,
            cookies:{
                required: '1',
                marketing: '0',
                statistic: '0'
            },
            expiredDate: 60 * 60 * 24 * 30,
            ageGateFilled: false            
        }
    },
    computed:{   
    },    
    directives: {
        mask
    },
    mounted(){
      var _this = this

      if ( parseInt(this.$cookies.get("required_cookie")) === 1 ){          
        _this.needCookieSettings = false
        // this.setGTMScriptTag();    
      }

      if (this.needCookieSettings){
          document.body.classList.add('noScroll');
      } else {
          document.body.classList.remove('noScroll');
      }

        //finish the process with Enter key
        // document.addEventListener("keyup", function(event) {
        //     if (event.code === 'Enter') {
        //         // console.log('Enter is pressed!');
        //         _this.checkAgeGateAndAllCookies()
        //     }
        // });
    }, 
    watch:{      
        ageGateInputYear(){            
            if (this.ageGateInputYear.length === 4) {                
                this.isValidYear()                
            }           
            this.isAgeGateFilled()
        },
        ageGateInputMonth() {            
            if (this.ageGateInputMonth.length === 2){
                this.isValidMonth()                
            }            
            this.isAgeGateFilled()
        },
        ageGateInputDay(){            
            this.isValidDay()
            this.isAgeGateFilled()
        }
    },
    methods:{   
        isValidYear(){            
            var _this = this
            var currentYear = new Date();
            var goodYear = parseInt(currentYear.getFullYear()) - 18;                        

            if ( parseInt(this.ageGateInputYear) > goodYear || parseInt(this.ageGateInputYear) < 1910 || this.ageGateInputYear == null || this.ageGateInputYear == '') {
                _this.$refs.ageGateYear.classList.add("error-input")           
                return false
            } else {
                // console.log('valid year')
                _this.$refs.ageGateYear.classList.remove("error-input")      
                _this.$refs.ageGateMonth.focus()
                return true
            }
        },
        isValidMonth(){
            var _this = this

            if ( this.ageGateInputMonth == null || this.ageGateInputMonth == '' || parseInt(this.ageGateInputMonth) > 12 || parseInt(this.ageGateInputMonth) < 1) {
                _this.$refs.ageGateMonth.classList.add("error-input")  
                return false
            } else {
                _this.$refs.ageGateMonth.classList.remove("error-input")      
                _this.$refs.ageGateDay.focus()            
                return true
            }
        },
        isValidDay(){
            var _this = this

            if ( this.ageGateInputDay == null || this.ageGateInputDay == '' || parseInt(this.ageGateInputDay) > 31 || parseInt(this.ageGateInputDay) < 1) {                
                _this.$refs.ageGateDay.classList.add("error-input")  
                return false                
            } else {
                if ( 
                    ( 
                        parseInt(_this.ageGateInputMonth) === 4 || 
                        parseInt(_this.ageGateInputMonth) === 6 || 
                        parseInt(_this.ageGateInputMonth) === 9 || 
                        parseInt(_this.ageGateInputMonth) === 11
                    ) && parseInt(_this.aageGateInputDay == 31) ) {                        
                        //30 day month check
                        _this.$refs.ageGateDay.classList.add("error-input")  
                        return false
                } else if ( parseInt(_this.ageGateInputMonth) === 2 && parseInt(_this.aageGateInputDay) > 29){                                   
                    //february check
                    _this.$refs.ageGateDay.classList.add("error-input")  
                    return false                    
                } else {                    
                    _this.$refs.ageGateDay.classList.remove("error-input")  
                    return true
                }
            }
        },     
        isValidDate(){            

            if (this.isValidYear() && this.isValidMonth() && this.isValidDay() ){                                                
                return true
            } else {
                return false
            }
            
        },
        isValidAge(){
            var _this = this            

            //is every input is filled?
            if ( _this.ageGateInputYear !== null && _this.ageGateInputMonth !== null && _this.ageGateInputDay !== null) {            
                //over than 18 age             

                if (_this.isValidDate()) {
                    //every input is filled and good value
                    let birthDateY = parseInt(_this.ageGateInputYear);
                    let birthDateM = parseInt(_this.ageGateInputMonth);
                    let birthDateD = parseInt(_this.ageGateInputDay);      
                                            
                    var birthdate = new Date();
                    birthdate.setFullYear(birthDateY, birthDateM-1, birthDateD);

                    var currdate = new Date();
                    currdate.setFullYear(currdate.getFullYear() - 18);

                    // console.log('setfullyear', currdate, birthdate, (currdate - birthdate) )
                    
                    if ((currdate - birthdate) < 0){                          
                        _this.ageGateError = "Csak 18 éven felüliek tekinthetik meg az oldalt."     
                        return false;
                    } else {                                                       
                        return true;
                    }
                }
                
            } else {
                // there is empty input field
                _this.ageGateError = "Kérünk, töltsd ki az összes mezőt!"

                if (_this.aageGateInputYear == null){
                    _this.$refs.ageGateYear.classList.add("error-input")                    
                }
                if (_this.ageGateInputMonth == null){
                    _this.$refs.ageGateMonth.classList.add("error-input")                    
                }
                if (_this.ageGateInputDay == null){
                    _this.$refs.ageGateDay.classList.add("error-input")                    
                }

                return false
            }
            
        },
        checkAgeGateAndAllCookies(){
            var _this = this

            // GA4 *************** //
            let btnSucc = this.isValidAge() ? 'sikeres' : 'sikertelen'
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'agegate',
                'action': 'tovabb_gombra_kattintas',
                'label': btnSucc,
                'page_type': 'agegate',
                'button': 'tovabb',
                'clicked_text':'Folytatom az ajánlott sütikkel',
                'success': btnSucc,
                'action_type': 'gomb_kattintas'
            })

            // GA4 END *********** //

            if (this.isValidAge()) {
                _this.acceptAllCookies()                
            } 
        },
        acceptAllCookies(){            

            // required_cookie, marketing_cookie, statistic_cookie            
            this.$cookies.set('required_cookie', 1, this.expiredDate);
            this.$cookies.set('marketing_cookie', 1, this.expiredDate);
            this.$cookies.set('statistic_cookie', 1, this.expiredDate);

            this.needCookieSettings = false
            // this.setGTMScriptTag();      
            document.body.classList.remove('noScroll');
                        
        },      
        checkAgeGateAndSettingsCookies(){
            // GA4 *************** //
            let btnSucc = this.isValidAge() ? 'sikeres' : 'sikertelen'
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'agegate',
                'action': 'tovabb_gombra_kattintas',
                'label': btnSucc,
                'page_type': 'agegate',
                'button': 'tovabb',
                'clicked_text':'Mentés és elfogadás',
                'success': btnSucc,
                'action_type': 'gomb_kattintas'
            })

            // GA4 END *********** //

            if (this.isValidAge()) {
                this.$cookies.set('required_cookie', 1, this.expiredDate);
                if ( parseInt(this.cookies.marketing) === 1 ){
                    this.$cookies.set('marketing_cookie', 1, this.expiredDate);
                }
                if ( parseInt(this.cookies.statistic) === 1 ){
                    this.$cookies.set('statistic_cookie', 1, this.expiredDate);
                }

                this.needCookieSettings = false     
                document.body.classList.remove('noScroll');
                
            }
        } ,
        isAgeGateFilled(){
            if (
                this.ageGateInputYear && this.ageGateInputYear.length === 4 &&
                this.ageGateInputMonth && this.ageGateInputMonth.length === 2 &&
                this.ageGateInputDay && this.ageGateInputDay.length == 2
            ) {
                this.ageGateFilled = true
            } else {
                this.ageGateFilled = false
            }
        }
    }
}
</script>

<style scoped>

</style>