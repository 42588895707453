import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const timeStamp = Math.floor(Date.now() / 1000)

export default new Vuex.Store({
  state: {
    showCookie: false,
    places: [],
    quotes: []
  },
  mutations: {
    getPlaces(state, response) {
      state.places = response;
    },
    getQuotes(state, response) {
      state.quotes = response;
    },
  },
  actions: {
    getPlaces({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'data/places.json?' + timeStamp).then(response => {
          commit("getPlaces", response.data.places)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getQuotes({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'data/quotes.json?' + timeStamp).then(response => {
          commit("getQuotes", response.data.quotes)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
  },
  modules: {
  }
})
