<template>
  <div>
    <div class="video-player" v-inview="'fade-up'">
        <div class="video-player__inner" 
            @click="videoPlay" 
            :class="{'inactive': !isVideoPlay}" 
            ref="videoPlayerWrap"
        >                                   
            <div class="poster-wrap" v-if="!isVideoPlay">
                <img :src="'https://www.gosser.hu/video/poster/' + poster" class="w-100" />

            </div>
            <video  width="100%" ref="videoPlayer" v-show="isVideoPlay">
                <source :src="'https://www.gosser.hu/video/' + video" type="video/mp4">
            </video>     
            <button class="btn--play" v-if="!isVideoPlay"></button>
        </div>        
    </div>
  </div>
</template>


<script>
export default {
    props:['video', 'poster'],
    data(){
        return {
            isVideoPlay: false,            
        }
    },        
    methods:{
        videoPlay(){    
            let _this = this                         

            if (_this.$refs.videoPlayer.paused){
                _this.$refs.videoPlayer.play()
                _this.isVideoPlay = true
            } else {
                _this.$refs.videoPlayer.pause()
                _this.isVideoPlay = false
                _this.showVideo = true
            }            
            
        },        
        scrollToNextBlock(){            
            let videoHeight = this.$refs.videoPlayerWrap.clientHeight

            window.scrollTo({
                left: 0, 
                top: videoHeight,
                behavior: 'smooth'
            });                 
                        
        }
    }
}
</script>