<template>
    <div id="quotes" class="quotes">
        <div class="container-xl">
            <div class="row" v-inview="'fade-up'">
                <div class="col-12 quotes-text__container text-center py-5">
                    <div class="mb-5">
                        <h2 class="text-uppercase mb-5">Napi slow life tipp</h2>
                        <p id="title" class="quotes-text text-uppercase">{{ quote }}</p>
                        <p style="font-size:20px;" class="mb-5">{{ subQuote }}</p>
                        <span class="more-btn text-white" v-on:click="getNewOne()">Új tippet kérek</span>
                    </div>
                    <div style="border-top: 1px solid rgba(0, 101, 41, 0.2);" class="pt-5">
                        <p class="cheers mb-5">Csatlakozz a lelassulók közösségéhez és mutasd meg a te <span>#gösserpillanat</span>-od!</p>
                        <div>
                            <a href="https://www.facebook.com/GosserHU" target="_blank"><img src="@/assets/images/icons/face.svg" alt="Gösser" class="mr-4"></a>
                            <a href="https://www.instagram.com/gosser_hu/" target="_blank"><img src="@/assets/images/icons/insta.svg" alt="Gösser"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            random: 0,
            continue: true,
            random_array: [],
            quote: "*SÉTÁLNI SZABAD",
            subQuote: "Ma tegyél egy frissítő, céltalan sétát. Figyeld a környezetet, gyönyörködj a homlokzatokban, és élvezd, ahogy a járda gördül a talpad alatt."
        }
    },
    computed:{
        quotes(){
            return this.$store.state.quotes
        },
    },
    methods: {
        getNewOne: function(){

            while(this.continue){
                this.random = this.getRandom();
                
                console.log(this.random, this.random_array.length, this.quotes.length);

                if(this.random_array.length != this.quotes.length){
                    document.querySelector('#title').classList.remove("animation");
                    if(!this.random_array.includes(this.random)){
                        this.random_array.push(this.random);
                        this.quote = this.quotes[this.random][0];
                        this.subQuote = this.quotes[this.random][1];
                        this.continue = false;
                        document.querySelector('#title').classList.add("animation");
                    }
                }else{
                    document.querySelector('#title').classList.remove("animation");
                    this.continue = false;
                    this.random_array = [];
                }
            }

            
            this.continue = true;
        },
        getRandom: function(){
            return Math.floor(Math.random() * this.quotes.length);
        },
    }
}
</script>



