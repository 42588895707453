<template>
    <div class="container-fluid " style="background-color: #006529;">
        <div class="row">
            <img src="@/assets/images/bgr/spritz-glasses.jpg" class="img-fluid article-hero-img">
        </div>
        <div class="row spritz-article-bg">
            <div class="col-12 col-lg-8 offset-lg-2 ">
                <div class="my-md-5 my-3 bg-white">
                    <h2>Páratlan koktélélmény bárhol, bármikor – Teraszozás a Gösser Spritz sörkoktélokkal</h2>
                    <p class="subtitle">A nyári teraszozások új sztárjai már elérhetők a vendéglátóhelyeken és a boltok polcain</p>
                    <p>Hány hozzávalóra van szükségünk egy jó koktélhoz? Mielőtt elkezdenénk felsorolni és összeszámolni kedvenc italaink komponenseit, segítünk: a helyes válasz: egy. Igen, a Gösser Spritz sörkoktélok segítségével azonnal egy koktélbárban érezhetjük magunkat a legkülönbözőbb helyszíneken. Az epres Gösser Beer Spritz „Strawberry” és a borókabogyó ízesítésű Gösser Beer Spritz „Botanic” sörkoktélok tökéletesen illenek bármelyik nyári pillanathoz, legyen szó baráti összejövetelekről, kerti partikról vagy meghitt, teraszon töltött estékről.</p>
                    <h3>Koktélbár, ahol csak akarod</h3>
                    <p>A dobozos kiszerelésnek köszönhetően egyszerűen és kényelmesen készíthetünk stílusos koktélokat, melyeket néhány szelet friss eperrel vagy citrommal, esetleg mentalevéllel tehetünk még különlegesebbé. A Gösser Beer Spritz tökéletes választás lehet a színes izzókkal megvilágított teraszunkon, akár egy romantikus estén a párunkkal, akár a barátnőinkkel beszélgetve, vagy a hétvégi grillezés alkalmával.</p>
                    <h3>Stílusos megjelenés a szabadság és a koktélozás egyedülálló szinergiájával </h3>
                    <p>A koktélok világa inspirálta italkülönlegességek készen állnak arra, hogy felrázzák az idei teraszszezon kínálatát. Az epres és borókabogyó ízesítésű italok nem csak ízükben hoznak üdítő frissességet. A Gösser Beer Spritz Strawberry csapolt változatát már számos szórakozó- és vendéglátóhelyen ikonikus talpaspohárban szervírozzák, amely jégre töltve és az extra gyümölcsökkel a forró nyár esti pillanatokról készült fotókon is látványos kiegészítő lehet. </p>
                    <h3>Dobozba zárt ízvarázs</h3>
                    <p>Nem kell lemondanunk a koktélozásról akkor sem, ha inkább a természet közelségét élveznénk. Egy erdei túra, vagy folyóparti piknik során eddig elképzelhetetlen és megoldhatatlan feladat lett volna egy finom koktél elfogyasztása, innentől nem az. A hátizsákokból csak elő kell vennünk a kedvenc Gösser Beer Spritzünket és élvezhetjük is a kifinomult, könnyed, gyümölcsös ízt.</p>
                    <p>A Gösser Spritz sörkoktélok a Gössertől megszokott prémium minőséget képviselik. A páratlan ízélmény adott, a szervírozás opcionális a helyszínnek és a hangulatunknak megfelelően: fogyaszthatjuk talpaspohárba, jégre töltve, eperrel vagy egy szelet citrommal, akár mentával megbolondítva –, csapolva, vagy egyszerűen csak a 0,33-as dobozból, üvegből. Bármelyiket válasszuk is, nem fogjuk megbánni, induljon a teraszozás!</p>
                    <img src="@/assets/images/article/spritz1.jpg" class="img-fluid my-4">
                </div>
            </div>
        </div>
        
         <Contact />
    </div>
</template>

<script>
import Contact  from '@/components/contact.vue'

export default {  
    components:{
        Contact           
    }
}
</script>


