<template> <!-- :class="{'scrollDown': scrollDown}" -->
  <div class="header">

    <nav class="navbar navbar-dark navbar-expand-lg position-fixed bg-gosser ">
      <a href="/" class="navbar-brand" @click="setGtm('Logo')"><img class="main-logo-nav" src="@/assets/images/design/logo.svg" alt="Gösser"></a>
      <div class="container-fluid nav-width">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-nav">
        <span class="navbar-toggler-icon"></span>
      </button>


      <!--<div class="navbar-collapse collapse dual-nav order-2 order-md-1 justify-content-start">
        <ul class="navbar-nav">
          <li class="nav-item" >
            <a class="nav-link" v-scroll-to="'#premium'" @click="setGtm('Gösser Premium')">Gösser Premium</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-scroll-to="'#zitrone'" @click="setGtm('Gösser Natur Zitrone')">Gösser Natur Zitrone</a>
          </li>
          <li class="nav-item">
            <a class="nav-link dp-sm-none" ></a>
          </li>
        </ul>
      </div>-->
      <div class="navbar-collapse collapse dual-nav  justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="{ el: '#quotes', offset: -100 }" @click="goToHomePage('#quotes'),setGtm('Slow tippek')">Slow tippek</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="'#premium'" @click="goToHomePage('#premium'),setGtm('Gösser Premium')">Gösser Premium</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="'#zitrone'" @click="goToHomePage('#zitrone'),setGtm('Gösser Natur Zitrone')">Gösser Natur Zitrone</a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" data-toggle="collapse" to="/beerspritz" data-target=".navbar-collapse.show" @click="setGtm('Gösser Spritz')">Gösser Beer Spritz <img src="@/assets/images/icons/uj.svg" alt=""></router-link>
          </li>
           <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="'#responsibility'" @click="goToHomePage('#responsibility'),setGtm('Fogyaszd felelősséggel')">Fogyaszd felelősséggel</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="{ el: '#helyszinek', offset: -100 }" @click="setGtm('Helyszínek')">Kóstoltatás</a>
          </li> -->
          <!--<li class="nav-item">
            <a class="nav-link" v-scroll-to="'#promociok'" @click="setGtm('Promóciók')">Promóciók</a>
          </li>-->
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" v-scroll-to="'#contact'" @click="goToHomePage('#contact'),setGtm('Kapcsolat')">Kapcsolat</a>
          </li>
        </ul>
      </div>

    </div>
    </nav>

  </div>
</template>

<script>
export default {
  data(){
    return{
      scrollDown:false

    }
  },
  methods:{
    setGtm(buttonText){
      this.GTtrackGA4({
            'event' : 'event',
            'category': 'menu',
            'action': 'fejlec',
            'label': buttonText,
            'menu': 'fejlec',
            'clicked_text': buttonText,
            'action_type': 'menu_kattintas'
        })
    },
    scrollDirection(){   
            let _this = this         
                        
            var st = window.pageYOffset || document.documentElement.scrollTop; 
            if (st > this.lastScrollTop){
                // downscroll
                // console.log('scroll down')
                _this.scrollDown = true
            } else {
                // upscroll 
                // console.log('scroll up')
                _this.scrollDown = false
            }
            this.lastScrollTop = st <= 0 ? 0 : st;             
        },
        goToHomePage(itemLink){
      
            if (this.$route.name != 'Home'){
            this.$router.push({name: 'Home'})

            setTimeout(() => {
                var element = document.getElementById(itemLink.substring(1))
                element.scrollIntoView()       
            }, 1000);
          }
        },
  },
  created(){
    let _this = this     
    
      window.addEventListener("scroll", function(){
          _this.scrollDirection()
      }, false);
  }
}
</script>

