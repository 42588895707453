<template>
  <div class="gosser-main">
     <!--<Header />-->
     <Hero />
     <About />
     <Quotes />
     <Video :video="'Gosser_DAN22HNKGSR0130.mp4'" :poster="'gosser_preview.jpg'" />
     <!--<Places />-->
     <Products />
     <Premium />
     <Responsibility />
     <Contact />
     <!--<Footer />-->
  </div>
</template>



<script>
//import Header from '@/components/header.vue'
//import Footer  from '@/components/footer.vue'
//import Places  from '@/components/places.vue'
import Quotes  from '@/components/quotes.vue'
import About  from '@/components/about.vue'
import Premium  from '@/components/premium.vue'
import Contact  from '@/components/contact.vue'
import Products  from '@/components/products.vue'
import Responsibility  from '@/components/responsibility.vue'
import Video  from '@/components/video.vue'
import Hero  from '@/components/hero.vue'



export default {
    data(){
        return {
            
        }
    },      

    components:{
      About,
      Quotes,
      //Places,
      Hero,
      Video,
      Responsibility,
      Products,
      Premium,
      //Footer,
      //Header,
      Contact           
    },

    created(){
       
    }
}
</script>
