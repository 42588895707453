<template>
    <div class="container-fluid" style="background-color: #006529;">
        <div class="row">
            <img src="@/assets/images/article/article-2-bg.jpg" class="img-fluid article-hero-img">
        </div>
        <div class="row spritz-article-bg">
            <div class="col-12 col-lg-8 offset-lg-2">
                <div class="my-md-5 my-3 bg-white">
                    <h2>Dobd fel a teraszod! 4 tipp, amitől városi oázissá válik az erkély</h2>
                    <p class="subtitle">Indul a teraszszezon! Ha eddig nem tetted, dobd fel az erkélyed néhány egyszerű kiegészítővel, és élvezd a nyári estéket egy pohár jéghideg koktéllal a kezedben - akár a barátaid társaságában, az otthonod kényelmében! Mutatjuk, mire lesz szükséged, hogy idén nyáron kimaxold a terasz-élményt!</p>

                    <h3>Hangulatos fények</h3>
                    <p>A reggeli kávénál talán csak a napot záró, hűvös koktél esik jobban a teraszon fogyasztva. Mivel az erkély gyakran színtere éjszakába nyúló baráti beszélgetéseknek és az esti lazításnak, érdemes gondoskodni a megfelelő világításról . A pislákoló gyertyák és a korlátra rögzített fényfüzérek otthonos hangulatot kölcsönöznek az erkélynek, miközben elegendő fényt is adnak az esti programokhoz.</p>
                    <h3>Növények a dzsungelhangulatért</h3>
                    <p>A növények nemcsak hangulatosabbá teszik az erkélyt, de árnyat adhatnak és a levegő minőségén is javítanak. A buja zöld levelekről akkor sem kell lemondanod, ha talpalatnyi hely áll csak rendelkezésedre: a falra szerelhető növénytartókkal vertikális „kertet” építhetsz, de ha van rá lehetőséged, lógass le kaspókat fölülről is, így szó szerint körülölelhetnek a levelek!</p>
                    
                    <h3>Puha párnák és kényelmes bútorok</h3>
                    <p>Miért ne vinnéd ki a nappalid kényelmét a teraszodra is? A színes, puha párnák, vidám textíliák mellett akár egy kis méretű szőnyeg is kerülhet az erkélyre. Válassz időjárásálló, mosógépben mosható darabokat, és egész szezonban élvezheted a látványukat! A kényelmes ülőalkalmatosság mellett, ami lehet egy fotel, egy pad, de akár egy hintaágy is, ne feledkezz meg egy asztalkáról sem, amire a könyvedet vagy poharadat leteheted.</p>
                    <h3>Tökéletesen szervírozott koktélok</h3>
                    <p>A teraszozás élményét egy jéghideg koktéllal a kezedben élvezheted a legjobban, és szerencsére egy tökéletes italt a poharadba tölteni sem bonyolultabb, mint belehuppanni a párnákkal bélelt hintaszékbe.</p>
                    <p>A koktélok világa ihlette Gösser Beer Spritz dobozba zárt, fogyasztásra kész sörkoktélok pillanatok alatt páratlan koktélélményt kínálnak bárhol, bármikor - akár a saját teraszodon is.</p>
                    <p>A legtökéletesebb ízélmény érdekében a Gösser sörkoktélt talpaspohárba, jégre töltve érdemes fogyasztani, gyümölcsökkel vagy mentával megbolondítva. Az epres ízű Gösser Beer Spritzet csapolt formában a kedvenc kiülős helyeiden is fogyaszthatod, de a dobozos vagy üveges sörkoktélokat akár a szívednek legkedvesebb természetközeli helyekre is magaddal viheted. Így még akkor sem kell lemondanod a szabadtéri koktélozás élményéről, ha nincs saját teraszod!</p>
                    <img src="@/assets/images/article/spritz2.jpg" class="img-fluid my-4">
                </div>
            </div>
        </div>
        
        <Contact />
    </div>
</template>

<script>
import Contact  from '@/components/contact.vue'

export default {  
    components:{
        Contact           
    }
}
</script>


