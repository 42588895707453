import Vue from 'vue'
import App from './App.vue'
import router from './setup/router.js'
import store from './setup/store.js'


import 'bootstrap'
import {BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/css/main.min.css'
import CookieBanner from '@/plugins/cookiebanner/cookie-banner.js'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(CookieBanner)
Vue.use(BootstrapVue)


var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

//mixins
import globalMI from './mixins/globalMI.js';
Vue.mixin(globalMI)


// in view directive
Vue.directive('inview', {  
  bind(el){
    el.classList.add('animateScroll')   
  },
  inserted(el, binding) {         

      function isInViewport(item, classname){        
        let rect = item.getBoundingClientRect(); 
        let windowHeight = window.innerHeight
        
        var classnameList = classname.split(' ')
        // console.log(classnameList)
        
        // console.log(windowHeight, item, rect.top)
        if ( rect.top >= 0 && rect.top < windowHeight ) {                     
          item.classList.add(...classnameList)          
        } 
      }

      function initAnimation(){        
        if (document.readyState === 'complete') {                    
            // el.classList.add('animateScroll')       
            isInViewport(el, binding.value)
            clearInterval(animPageLoad)
        }  
      }
                    
      var animPageLoad = setInterval(() => {
        initAnimation()
      }, 300);           
      
      window.addEventListener('scroll', function(){
        isInViewport(el, binding.value)
      })
      
   }   
 });



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
