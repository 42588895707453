<template>
  <div id="contact" class="contacts">
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <div class="contacts--main-title">
            <h2>Kapcsolat</h2>
          </div>
        </div>

        <div class="contacts--content">
            <div class="col-12 col-xl-4 contacts--card-wrap mr-xl-5" v-inview="'fade-up'">
                <div class="contacts--card">
                  <h3>HEINEKEN Hungária Sörgyárak Zrt.</h3>
                  <ul>
                    <li><img src="@/assets/images/contact/map-icon.svg" alt="icon">9400 Sopron, Vándor Sándor utca 1.</li>
                    <li><img src="@/assets/images/contact/globe-icon.svg" alt="icon"><a target="_blank" href='https://www.heinekenhungaria.hu/'>www.heinekenhungaria.hu</a></li>
                    <li><img src="@/assets/images/contact/phone-icon.svg"><a href="tel:0699516200">06 (99) 516-200</a></li>
                  </ul>
                </div>
            </div>
            <div class="col-12 col-xl-4 contacts--card-wrap" v-inview="'fade-up'">
                <div class="contacts--card">
                  <h3>Kereskedelmi Központ</h3>
                  <ul>
                    <li><img src="@/assets/images/contact/map-icon.svg" alt="icon">1087 Budapest, Hungária krt. 30/A  <br>
                        Arena Business Campus 6. emelet</li>
                    <li><img src="@/assets/images/contact/phone-icon.svg" alt="icon"><a href="tel:0614372155">06 (1) 437-2155</a></li>
                    <li><img src="@/assets/images/contact/at-icon.svg"><a href="mailto:info@heineken.hu">info@heineken.hu</a></li>
                  </ul>
                </div>
            </div>
            <div class=" col-12 col-xl-4 contacts--card-wrap d-none" v-inview="'fade-up'">
                <div class="contacts--card">
                  <h3>Sörgyárlátogatás</h3>
                  <ul>
                    <li><img src="@/assets/images/contact/globe-icon.svg" alt="icon"><a href="https://www.heinekenhungaria.hu/sorgyartura" target="_blank">www.heinekenhungaria.hu/sorgyartura</a></li>
                    <li><img src="@/assets/images/contact/at-icon.svg" alt="icon"><a href="mailto:sorgyartura@heineken.hu">sorgyartura@heineken.hu</a></li>
                  </ul>
                </div>
            </div>

          </div>

        
      </div>
    </div>

  </div>
</template>


<script>
export default {
  methods:{
    setGtm(buttonText){
      this.GTtrackGA4({
            'event' : 'event',
            'category': 'social',
            'action': 'kattintas',
            'label': buttonText,
            'social': buttonText,
            'action_type': 'gomb_kattintas'
        })
    }
  }
}
</script>



